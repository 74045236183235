<template>
  <div class="page">
    <div class="search-box">
      <el-row style="margin-top: 20px; margin-bottom: 20px">
        <el-col :span="4">
          <el-input v-model="keyWord" class="condition-search" size="mini" prefix-icon="el-icon-search"
            placeholder="请输入备注" @keyup.enter.native="search()" />
        </el-col>
        <el-col :span="4" :offset="1">
          <el-input v-model="mobile" class="condition-search" size="mini" prefix-icon="el-icon-search"
            placeholder="请输入手机号" @keyup.enter.native="search()" />
        </el-col>
        <el-col :span="2" :offset="1">
          <el-button class="college-btn" type="primary" size="mini" @click="search()">搜索</el-button>
        </el-col>
      </el-row>
    </div>

    <div class="expert_list" v-loading="loading">
      <div class="item" v-for="(item, i) in listData" :key="i">
        <div class="content">
          <div class="left">
            <img :src="item.logo" alt="" v-if="item.logo" />
            <img v-else src="https://img.bkgaoshou.com/img/20210518/56bda6b47ffc9149ee2ff777c8be9f97.jpg" alt="" />
          </div>
          <div class="right">
            <div class="name">
              <span>{{ item.name }}</span> <span style="color:rgb(255, 102, 0);">{{ item.remarks }}
              </span>
            </div>
            <div class="brief" v-html="item.intro">
            </div>

          </div>
        </div>
        <div class="del">
          <span @click="handleEdit(item.id)">编辑</span> |
          <span @click="del(item.id)">删除</span>
        </div>
      </div>
      <div class="item" v-if="show">
        <router-link to="/expert/expert" style="color: black">
          <div class="content">
            <div class="add">+</div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="pagination">
      <el-pagination :hide-on-single-page="true" :page-size="pageSize" :total="total" class="page-box"
        layout="prev, pager, next" @current-change="pageChange" />
    </div>
    <el-dialog :title="dialog.title" :visible.sync="dialog.centerDialogVisible" :close-on-click-modal="false" width="65%">
      <el-form ref="form" :model="formData" label-width="180px" :inline="true" style="margin-top: 40px">
        <el-form-item label="导师姓名">
          <el-input v-model="formData.name" size="mini"></el-input>
        </el-form-item>
        <br />
        <el-form-item label="手机号">
          <el-input v-model="formData.mobile" size="mini"></el-input>
        </el-form-item>
        <br />
        <el-form-item label="登陆密码">
          <el-input v-model="formData.pwd" size="mini"></el-input>
        </el-form-item>
        <br />
        <el-form-item label="导师简介">
          <vue-tinymce v-model="formData.intro" :setting="setting" />
        </el-form-item>
        <br />
        <el-form-item label="收费标准">
          <el-input v-model="formData.fee" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-input v-model="formData.sex" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="formData.address" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="职称">
          <el-input v-model="formData.academic_title" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-input v-model="formData.status" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="后台备注">
          <el-input v-model="formData.remarks" size="mini"></el-input>
        </el-form-item>
        <br />
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="add">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
//time: 2021/1/21
import { VueTinymce } from "@packy-tang/vue-tinymce";
export default {
  name: "ExpertList",
  components: {
    VueTinymce,
  },
  data() {
    return {
      show: true,
      listData: [],
      keyWord: '',
      mobile:'',
      ExpertInfo: "",
      dialog: {
        centerDialogVisible: false,
      },
      loading: false,
      setting: {},
      pageNum: 1,
      pageSize: 10,
      total: 0,
      formData: {
        name: "",
        logo: "",
        mobile: "",
        pwd: "",
        fee: "",
        sex: "",
        address: "",
        academic_title: "",
        intro: "",
        desc: "",
        status: "",
        remarks: ""
      },
    };
  },
  created() {
    this.ExpertInfo = JSON.parse(localStorage.getItem("ExpertInfo"));
    this.getList();
  },
  methods: {
    search() {
      this.pageNum = 1
      this.getList()
    },
    getList() {
      this.loading = true;
      const data = {
        remarks: this.keyWord,
        mobile:this.mobile,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        parent_id: this.ExpertInfo.id,
      };
      this.$fecth.post("expert/getData", data).then((res) => {
        this.loading = false;
        const { code, msg, data } = res;
        if (code == 200) {
          this.listData = data.lists;
          this.total = data.total;
          if (this.pageNum == data.pages) {
            this.show = true;
          } else {
            this.show = false;
          }
        } else {
          this.showTips(0, msg);
        }
      });
    },
    pageChange(page) {
      this.pageNum = page;
      this.getList();
    },
    del(id) {
      const data = {
        id: id,
      };
      this.$fecth.post("expert/delete", data).then((res) => {
        const { code, msg } = res;
        if (code == 200) {
          this.getList();
          this.showTips(1, msg);
        } else {
          this.showTips(0, msg);
        }
      });
    },
    handleEdit(id) {
      this.formData.id = id;
      const data = {
        id: id,
      };
      this.$fecth.post("expert/getDataById", data).then((res) => {
        const { code, msg, data } = res;
        if (code == 200) {
          this.formData = data;
          this.dialog.centerDialogVisible = true;
        } else {
          this.showTips(0, msg);
        }
      });
    },
    add() {
      const data = {
        id: this.formData.id,
        parent_id: this.ExpertInfo.id,
        mobile: this.formData.mobile,
        name: this.formData.name,
        logo: this.formData.logo,
        pwd: this.formData.pwd,
        fee: this.formData.fee,
        sex: this.formData.sex,
        address: this.formData.address,
        academic_title: this.formData.academic_title,
        intro: this.formData.intro,
        remarks: this.formData.remarks,
        desc: this.formData.desc,
        status: this.formData.status,
      };
      this.$fecth.post("expert/edit", data).then((res) => {
        const { code, msg } = res;
        if (code == 200) {
          this.dialog.centerDialogVisible = false;
          this.showTips(1, msg);
          this.getList();
        } else {
          this.showTips(0, msg);
        }
      });
    },
    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.search-box {
  background: white;
  padding: 10px 30px;
}

.pagination {
  margin-top: 10px;
  background: white;
}

.expert_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .item {
    background: white;
    margin-top: 20px;
    width: 49.5%;

    .content {
      padding: 15px;
      //   border: 1px solid rgb(172, 172, 172);
      height: 150px;

      .left {
        display: inline-block;
        width: 100px;
        height: 120px;
        vertical-align: top;
        overflow: hidden;
        margin-right: 15px;

        img {
          width: 100%;
        }
      }

      .right {
        vertical-align: top;
        width: 320px;
        display: inline-block;

        .name {
          span:first-child {
            margin-right: 10px;
          }

          margin-bottom: 10px;
        }

        .brief {
          text-indent: 2rem;
          font-size: 14px;
          letter-spacing: 1px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          line-clamp: 5;
          -webkit-box-orient: vertical;
        }
      }

      .add {
        text-align: center;
        line-height: 140px;
        font-size: 120px;
        font-weight: 100;
      }
    }

    .del {
      color: #cfcfcf;
      font-size: 14px;
      line-height: 40px;
      text-align: right;

      span {
        padding: 0 10px;
      }
    }
  }
}
</style>